* {
	box-sizing: border-box;
}

.wrapper {
	height: 40px;
	width: 200px;
	margin: 10px 0;
	position: relative;
	border-radius: 5px;
}
.wrapper::before {
	content: '';
	display: block;
	position: absolute;
	top: -7px;
	width: 100%;
	height: 10px;
	border-radius: 200px;
	background: black;
	opacity: 0;
	transition: opacity 0.05s;
}
.hover::before {
	opacity: 0.2;
}

.item {
	height: inherit;
	width: 100%;
	display: flex;
	background: #eee;
	border-radius: 5px;
	overflow: hidden;

	opacity: 1;
	transition: opacity 0.1s;
}
.grabbing {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
	opacity: 0.9;
}

.handle {
	height: 40px;
	width: 40px;
	padding: 10px;
	float: left;
	cursor: grab;
}
.handle svg {
	height: 100%;
	width: 100%;
}

.content {
	padding: 10px;
	flex: 1 0;
}
